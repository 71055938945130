<template>
  <div id="dashboard">
    <div
      class="container-fluid ptb-responsive"
      v-if="$store.state.courts && $store.state.courts.courtsGroup"
    >
      <!-- ROW 1 -->
      <div class="col-md-12">
        <div class="row">
          <!-- INDOOR SHOW COURT -->
          <div class="col-md-5 pr-3 pl-0 pt-0 pb-0" id="show-court">
            <div class="col-md-12 pl-2 pr-2 pt-4 pb-2">
              <h3 class="m-0 title-heading">INDOOR SHOW COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <div class="row">
                <div
                  v-for="(isc, index) in $store.state.courts.courtsGroup
                    .indoor_show_court"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="isc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="isc.online == 0">
                        <img
                          src="@/assets/icon_wireless.svg"
                          class="size-icon"
                        />
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="
                              col-md-12
                              p-0
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                isc.online == 0 ? 'not-connection-button' : ''
                              } ${isc.expected_on == 0 ? 'off-state' : ''}
                                  `"
                              type="button"
                              :disabled="isc.expected_on != isc.on"
                              v-model="isc.name"
                              @click="
                                CheckOnOffState('indoor_show_court', index)
                              "
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="border"
                            v-for="(item_mode, subIndex) in isc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="isc.expected_mode != isc.mode"
                              :class="`${
                                isc.expected_mode != isc.mode &&
                                isc.expected_mode == isc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : isc.expected_mode == isc.mode &&
                                    isc.mode == isc.modes[subIndex] &&
                                    isc.on == isc.expected_on &&
                                    isc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                isc.online == 0 ? 'not-connection-button' : ''
                              } ${
                                !isc.expected_on || !isc.on ? 'not-click' : ''
                              }
                                  `"
                              type="button"
                              v-model="isc.modes[subIndex]"
                              @click="
                                CheckModeState(
                                  'indoor_show_court',
                                  index,
                                  subIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            isc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led">
                            <span class="text-13"
                              >{{
                                getRoundDecimal(isc.power_consumption)
                              }}
                              kW</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6 border">
                          <div class="wp-led" v-html="getStateCourt(isc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END INDOOR SHOW COURT -->

          <!-- INDOOR PRACTICE COURT -->
          <div class="col-md-7 pl-3 pr-0 pt-0 pb-0" id="practice-court">
            <div class="col-md-12 pl-2 pr-2 pt-4 pb-2">
              <h3 class="m-0 title-heading">INDOOR PRACTICE COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <div class="row">
                <div
                  v-for="(ipc, index) in $store.state.courts.courtsGroup
                    .indoor_practice_court"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="ipc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="ipc.online == 0">
                        <img
                          src="@/assets/icon_wireless.svg"
                          class="size-icon"
                        />
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="
                              col-md-12
                              p-0
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                ipc.online == 0 ? 'not-connection-button' : ''
                              } ${ipc.expected_on == 0 ? 'off-state' : ''}
                                  `"
                              type="button"
                              :disabled="ipc.expected_on != ipc.on"
                              v-model="ipc.name"
                              @click="
                                CheckOnOffState('indoor_practice_court', index)
                              "
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            v-for="(item_mode, subIndex) in ipc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="ipc.expected_mode != ipc.mode"
                              :class="`${
                                ipc.expected_mode != ipc.mode &&
                                ipc.expected_mode == ipc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : ipc.expected_mode == ipc.mode &&
                                    ipc.mode == ipc.modes[subIndex] &&
                                    ipc.expected_on == ipc.on &&
                                    ipc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                ipc.online == 0 ? 'not-connection-button' : ''
                              } ${
                                !ipc.expected_on || !ipc.on ? 'not-click' : ''
                              }
                                  `"
                              type="button"
                              v-model="ipc.modes[subIndex]"
                              @click="
                                CheckModeState(
                                  'indoor_practice_court',
                                  index,
                                  subIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            ipc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led">
                            <span class="text-13"
                              >{{
                                getRoundDecimal(ipc.power_consumption)
                              }}
                              kW</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6 border">
                          <div class="wp-led" v-html="getStateCourt(ipc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END INDOOR PRACTICE COURT -->
        </div>
      </div>
      <!-- END ROW 1 -->

      <!-- ROW 2 -->
      <div class="col-md-12">
        <div class="row">
          <!-- OUTDOOR TENNIS COURT -->
          <div class="col-md-7 pr-1 pl-0 pt-0 pb-0" id="tennis-court">
            <div class="col-md-12 pl-2 pr-2 pt-5 pb-2">
              <h3 class="m-0 title-heading">OUTDOOR TENNIS COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <!-- ROW 1 OF OUTDOOR TENNIS COURT -->
              <div class="row">
                <div
                  class="mt-tennis-court"
                  v-for="(otc, index) in $store.state.courts.courtsGroup
                    .outdoor_tennis_court"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="otc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="otc.online == 0">
                        <img
                          src="@/assets/icon_wireless.svg"
                          class="size-icon"
                        />
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="
                              col-md-12
                              p-0
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                otc.online == 0 ? 'not-connection-button' : ''
                              } ${otc.expected_on == 0 ? 'off-state' : ''}
                                  `"
                              type="button"
                              :disabled="otc.expected_on != otc.on"
                              v-model="otc.name"
                              @click="
                                CheckOnOffState('outdoor_tennis_court', index)
                              "
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            v-for="(item_mode, subIndex) in otc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="otc.expected_mode != otc.mode"
                              :class="`${
                                otc.expected_mode != otc.mode &&
                                otc.expected_mode == otc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : otc.expected_mode == otc.mode &&
                                    otc.mode == otc.modes[subIndex] &&
                                    otc.expected_on == otc.on &&
                                    otc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                otc.online == 0 ? 'not-connection-button' : ''
                              } ${
                                !otc.expected_on || !otc.on ? 'not-click' : ''
                              }
                                  `"
                              type="button"
                              v-model="otc.modes[subIndex]"
                              @click="
                                CheckModeState(
                                  'outdoor_tennis_court',
                                  index,
                                  subIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            otc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led">
                            <span class="text-13"
                              >{{
                                getRoundDecimal(otc.power_consumption)
                              }}
                              kW</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6 border">
                          <div class="wp-led" v-html="getStateCourt(otc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END ROW 1 OF OUTDOOR TENNIS COURT -->
            </div>
          </div>
          <!-- END OUTDOOR TENNIS COURT -->

          <!-- JUNIOR COURT -->
          <div class="col-md-2 pl-2 pr-2 pt-0 pb-0" id="junior-court">
            <div class="col-md-12 pl-2 pr-2 pt-5 pb-2">
              <h3 class="m-0 title-heading">JUNIOR COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <!-- ROW 1 OF JUNIOR COURT -->
              <div class="row">
                <div
                  class="mt-junior-court"
                  v-for="(jc, index) in $store.state.courts.courtsGroup.junior"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="jc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="jc.online == 0">
                        <img
                          src="@/assets/icon_wireless.svg"
                          class="size-icon"
                        />
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="
                              col-md-12
                              p-0
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                jc.online == 0 ? 'not-connection-button' : ''
                              } ${jc.expected_on == 0 ? 'off-state' : ''}
                                  `"
                              type="button"
                              :disabled="jc.expected_on != jc.on"
                              v-model="jc.name"
                              @click="CheckOnOffState('junior', index)"
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            v-for="(item_mode, subIndex) in jc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="jc.expected_mode != jc.mode"
                              :class="`${
                                jc.expected_mode != jc.mode &&
                                jc.expected_mode == jc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : jc.expected_mode == jc.mode &&
                                    jc.mode == jc.modes[subIndex] &&
                                    jc.expected_on == jc.on &&
                                    jc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                jc.online == 0 ? 'not-connection-button' : ''
                              } ${!jc.expected_on || !jc.on ? 'not-click' : ''}
                                  `"
                              type="button"
                              v-model="jc.modes[subIndex]"
                              @click="CheckModeState('junior', index, subIndex)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            jc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led">
                            <span class="text-13"
                              >{{
                                getRoundDecimal(jc.power_consumption)
                              }}
                              kW</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6 border">
                          <div class="wp-led" v-html="getStateCourt(jc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END ROW 1 OF JUNIOR COURT -->
            </div>
          </div>
          <!-- END JUNIOR COURT -->

          <!-- ROOFTOP COURT -->
          <div class="col-md-3 pl-1 pr-0 pt-0 pb-0" id="rooftop-court">
            <div class="col-md-12 pl-2 pr-2 pt-5 pb-2">
              <h3 class="m-0 title-heading">ROOFTOP COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <!-- ROW 1 OF ROOFTOP COURT -->
              <div class="row">
                <div
                  class="mt-rooftop-court"
                  v-for="(rc, index) in $store.state.courts.courtsGroup
                    .rooftop_court"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="rc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="rc.online == 0">
                        <img
                          src="@/assets/icon_wireless.svg"
                          class="size-icon"
                        />
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="
                              col-md-12
                              p-0
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                rc.online == 0 ? 'not-connection-button' : ''
                              } ${rc.expected_on == 0 ? 'off-state' : ''}
                                  `"
                              type="button"
                              :disabled="rc.expected_on != rc.on"
                              v-model="rc.name"
                              @click="CheckOnOffState('rooftop_court', index)"
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            v-for="(item_mode, subIndex) in rc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="rc.expected_mode != rc.mode"
                              :class="`${
                                rc.expected_mode != rc.mode &&
                                rc.expected_mode == rc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : rc.expected_mode == rc.mode &&
                                    rc.mode == rc.modes[subIndex] &&
                                    rc.expected_on == rc.on &&
                                    rc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                rc.online == 0 ? 'not-connection-button' : ''
                              } ${!rc.expected_on || !rc.on ? 'not-click' : ''}
                                  `"
                              type="button"
                              v-model="rc.modes[subIndex]"
                              @click="
                                CheckModeState('rooftop_court', index, subIndex)
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border p-rooftop-court"
                          :class="`${
                            rc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led">
                            <span class="text-13"
                              >{{
                                getRoundDecimal(rc.power_consumption)
                              }}
                              kW</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6 border p-rooftop-court">
                          <div class="wp-led" v-html="getStateCourt(rc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END ROW 1 OF ROOFTOP COURT -->
            </div>
          </div>
          <!-- END ROOFTOP COURT -->
        </div>
      </div>
      <!-- END ROW 2 -->
    </div>
    <!-- Message popup -->
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" style="padding-top: 6px">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button
                class="ml-4 btn btn-primary btn-custom"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <!-- End Message popup -->
  </div>
</template>

<style lang="scss" src="../assets/css/dashboard.scss"></style>
<script>
import { courtService } from "../services";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      interval: null,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      groupModes: {},
      firstLoad: true,
    };
  },
  async created() {
    await this.getListModes();
    await this.getListCourts();
  },
  mounted() {
    const vm = this;
    this.interval = setInterval(() => {
      // vm.testCourts();
      vm.getListCourts();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    getRoundDecimal(val) {
      return parseFloat(Math.round(val * 1000) / 1000);
    },
    getStateCourt(court) {
      if (court.led == "OK") {
        return "<span class='text-18 text-green'>OK</span>";
      } else {
        return "<span class='text-18 text-red'>" + court.led + "</span>";
      }
    },
    async CheckOnOffState(nameCourtsGroup, index) {
      if (
        this.$store.state.courts.courtsGroup[nameCourtsGroup][index]
          .scheduled == 1
      ) {
        this.apiMessage =
          "A schedule is currently running on the lighting. Please change the operation via Schedule screen.";
        this.apiTitle = "Warning";
        this.messageDialog = true;
      } else {
        let currentData =
          this.$store.state.courts.courtsGroup[nameCourtsGroup][index];
        currentData.expected_on = currentData.on == 1 ? 0 : 1;
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: nameCourtsGroup,
          currentData: currentData,
        });
        await this.changeCourtOnOff(currentData.id, {
          on: currentData.expected_on,
        });
        setTimeout(() => {
          this.handelUpdateCourtOn(currentData);
        }, this.TIMEOUT_UPDATE_COURT);
      }
    },
    changeCourtOnOff(id, data) {
      courtService.changeCourtOnOff(id, data).then(() => {});
    },
    async CheckModeState(nameCourtsGroup, index, subIndex) {
      if (
        this.$store.state.courts.courtsGroup[nameCourtsGroup][index]
          .scheduled == 1
      ) {
        this.apiMessage =
          "A schedule is currently running on the lighting. Please change the operation via Schedule screen.";
        this.apiTitle = "Warning";
        this.messageDialog = true;
      } else {
        let currentData =
          this.$store.state.courts.courtsGroup[nameCourtsGroup][index];
        currentData.expected_mode = currentData.modes[subIndex];
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: nameCourtsGroup,
          currentData: currentData,
        });
        await this.changeCourtMode(currentData.id, {
          mode: currentData.expected_mode,
        });
        setTimeout(() => {
          this.handelUpdateCourtMode(currentData);
        }, this.TIMEOUT_UPDATE_COURT);
      }
    },
    changeCourtMode(id, data) {
      courtService.changeCourtMode(id, data).then(() => {});
    },
    async getListModes() {
      if (
        !this.$store.state.courts.modes ||
        (this.$store.state.courts.modes &&
          this.$store.state.courts.modes.length <= 0)
      ) {
        await this.$store.dispatch("courts/getModes");
      }
      this.groupModes = await this.handelGroupModes(
        this.$store.state.courts.modes
      );
    },
    async getListCourts() {
      await this.$store.dispatch("courts/getCourts");
      let dataGroup = await this.handelGroupCourts(
        this.groupModes,
        this.$store.state.courts.courts
      );
      await this.$store.dispatch("courts/setCourtsGroup", dataGroup);
      if (this.firstLoad) {
        this.firstLoad = false;
        const filterCourts = this.$store.state.courts.courts.filter(function (
          court
        ) {
          return court.expected_on != court.on;
        });
        for (const filterCourt of filterCourts) {
          setTimeout(() => {
            this.handelUpdateCourtOn(filterCourt);
          }, this.TIMEOUT_UPDATE_COURT);
        }

        const filterCourtsMode = this.$store.state.courts.courts.filter(
          function (court) {
            return court.expected_mode != court.mode;
          }
        );
        for (const filterCourt of filterCourtsMode) {
          setTimeout(() => {
            this.handelUpdateCourtMode(filterCourt);
          }, this.TIMEOUT_UPDATE_COURT);
        }
      }
    },
    async testCourts() {
      await courtService.testCourts().then(() => {});
    },
    closeMessage() {
      this.messageDialog = false;
    },
    handelUpdateCourtOn(courtUpdate) {
      const checkData = this.$store.state.courts.courts.filter((item) => {
        return item.id == courtUpdate.id;
      });
      let currentData = checkData.length > 0 ? checkData[0] : null;
      if (currentData && currentData.expected_on != currentData.on) {
        currentData.expected_on = currentData.on;
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: this.GROUP_NAME[currentData.group],
          currentData: currentData,
        });
        this.changeCourtOnOff(currentData.id, { on: currentData.on });
        this.$forceUpdate();
      }
    },
    handelUpdateCourtMode(courtUpdate) {
      const checkData = this.$store.state.courts.courts.filter((item) => {
        return item.id == courtUpdate.id;
      });
      let currentData = checkData.length > 0 ? checkData[0] : null;
      if (currentData && currentData.expected_mode != currentData.mode) {
        currentData.expected_mode = currentData.mode;
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: this.GROUP_NAME[currentData.group],
          currentData: currentData,
        });
        this.changeCourtMode(currentData.id, { mode: currentData.mode });
        this.$forceUpdate();
      }
    },
  },
};
</script>
